// src/components/FlashcardGame/FlashcardSlider.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

const FlashcardSlider = ({ images }) => {
  const settings = {
    dots: false,
    infinite: images.length > 5, // Enable infinite scrolling only if more than 5 images
    speed: 6000, // Adjusted for slower movement (5 seconds)
    slidesToShow: Math.min(images.length, 5), // Show up to 5 images at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // No delay between slides for continuous movement
    cssEase: 'linear',
    pauseOnHover: true, // Pause the slider when hovered
    arrows: false, // Hide navigation arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(images.length, 3),
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(images.length, 2),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Back-side pattern image path
  const backPattern = '/img/game/back_pattern.jpeg'; // Ensure this image exists

  return (
    <div className="w-full">
      <Slider {...settings}>
        {images.map((imgSrc, index) => (
          <div key={index} className="px-2">
            <img
              src={backPattern}
              alt={`Back Side ${index + 1}`}
              className="w-full h-24 object-cover rounded-md shadow-md"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FlashcardSlider;
