// src/components/pages/QRQuestPage.js

import React, { useState, useEffect, useCallback } from 'react';
import { QrcodeIcon, CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import QrScanner from '../QrScanner';
import { api } from '../../auth/auth'; // Ensure this path is correct
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function QRQuestPage({ user, token }) {
  const [game, setGame] = useState(null);
  const [totalPuzzles, setTotalPuzzles] = useState(0);
  const [puzzlesLeft, setPuzzlesLeft] = useState(0);
  const [scanning, setScanning] = useState(false);
  const [currentPuzzle, setCurrentPuzzle] = useState(null);
  const [answer, setAnswer] = useState('');
  const [submitStatus, setSubmitStatus] = useState(null);
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Log user info on mount
  useEffect(() => {
    if (user) {
      console.log(`User logged in: ${user.username}`);
    }
  }, [user]);

  // Fetch active game on mount
  useEffect(() => {
    const fetchActiveGame = async () => {
      try {
        const response = await api.get('/qr-quest/games/active/');
        setGame(response.data);

        // Set total_puzzles
        if (response.data.total_puzzles !== undefined) {
          setTotalPuzzles(response.data.total_puzzles);
        } else {
          console.error('total_puzzles is missing from the game data.');
          setTotalPuzzles(0);
        }

        // Fetch puzzles left
        const puzzlesLeftResponse = await api.get(`/qr-quest/games/${response.data.id}/puzzles_left/`);
        setPuzzlesLeft(puzzlesLeftResponse.data.puzzles_left);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setSubmitStatus({
            type: 'error',
            message: 'No active game is currently available. Please try again later.',
          });
          setGame(null);
          setPuzzlesLeft(0);
          setTotalPuzzles(0);
        } else {
          console.error('Error fetching active game:', error);
          setSubmitStatus({
            type: 'error',
            message: 'Unable to fetch the active game. Please try again later.',
          });
          setGame(null);
          setPuzzlesLeft(0);
          setTotalPuzzles(0);
        }
      }
    };

    if (user && token) {
      fetchActiveGame();
    }
  }, [user, token]);

  // Handle successful scan
  const handleScanSuccess = useCallback(async (scannedCode) => {
    console.log('QR Code Scanned:', scannedCode);
    setScanning(false);
    setSubmitStatus(null);
    setLoading(true);
    try {
      const response = await api.get(`/qr-quest/puzzles/${scannedCode}/`);
      console.log('Fetched Puzzle:', response.data);

      if (response.data.is_solved) {
        setSubmitStatus({ type: 'info', message: 'You have already solved this puzzle.' });
        setCurrentPuzzle(null);
      } else {
        setCurrentPuzzle(response.data);
      }
    } catch (error) {
      console.error('Error fetching puzzle:', error);
      setSubmitStatus({ type: 'error', message: 'Invalid or non-existent QR code.' });
    } finally {
      setLoading(false);
    }
  }, []);

  // Handle scan cancellation
  const handleCancelScan = () => {
    setScanning(false);
    setSubmitStatus(null);
  };

  // Submit answer
  const handleSubmitAnswer = async () => {
    if (!answer.trim() || !game) return;
    setLoading(true);
    try {
      const response = await api.post(
        '/qr-quest/puzzles/submit/',
        {
          code: currentPuzzle.code,
          answer: answer.trim(),
          game_id: game.id,
        }
      );
      setSubmitStatus({ type: 'success', message: response.data.detail });
      setAnswer('');
      setCurrentPuzzle(null);
      
      // Refresh puzzles left and leaderboard
      await fetchPuzzlesLeft();
      await fetchLeaderboard();
    } catch (error) {
      console.error('Error submitting answer:', error);
      setSubmitStatus({
        type: 'error',
        message: error.response?.data?.detail || 'Error submitting answer.',
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch puzzles left
  const fetchPuzzlesLeft = useCallback(async () => {
    if (!game) return;
    try {
      const response = await api.get(`/qr-quest/games/${game.id}/puzzles_left/`);
      setPuzzlesLeft(response.data.puzzles_left);
    } catch (error) {
      console.error('Error fetching puzzles left:', error);
    }
  }, [game]);

  // Fetch leaderboard
  const fetchLeaderboard = useCallback(async () => {
    if (!game) return;
    try {
      const response = await api.get(`/qr-quest/games/${game.id}/leaderboard/`);
      setLeaderboard(response.data);
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
    }
  }, [game]);

  // Start scanning
  const startScanning = () => {
    setScanning(true);
    setSubmitStatus(null);
  };

  // End the game if no puzzles left <span className="text-white">Points: {user.points_qr}</span>
  useEffect(() => {
    if (puzzlesLeft === 0 && game) {
      fetchLeaderboard();
    }
  }, [puzzlesLeft, game, fetchLeaderboard]);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* App Bar */}
      <header className="bg-blue-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <QrcodeIcon className="h-8 w-8 text-white mr-2" />
              <h1 className="text-white text-xl font-semibold">QR Quest</h1>
            </div>
            <div className="flex items-center">
              <span className="text-white mr-4">{user.username}</span>
              
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="py-10">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="text-center">
              {/* Start Scan Button */}
              {!scanning && !currentPuzzle && puzzlesLeft > 0 && (
                <button
                  onClick={startScanning}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                >
                  <QrcodeIcon className="h-5 w-5 mr-2" />
                  Scan QR Code
                </button>
              )}

              {/* QR Scanner */}
              {scanning && (
                <div className="relative w-full h-96 mt-4">
                  <QrScanner onScanSuccess={handleScanSuccess} onCancel={handleCancelScan} />
                </div>
              )}

              {/* Display Loading Spinner */}
              {loading && (
                <div className="flex justify-center items-center mt-4">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              )}

              {/* Display Puzzle */}
              {currentPuzzle && (
                <div className="mt-6">
                  <h2 className="text-2xl font-semibold mb-4">{currentPuzzle.question}</h2>
                  {currentPuzzle.image_data && (
                    <img
                      src={`data:image/jpeg;base64,${currentPuzzle.image_data}`}
                      alt="Puzzle Visual"
                      className="mx-auto mb-4 max-w-full h-auto"
                    />
                  )}
                  <input
                    type="text"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    disabled={loading}
                    placeholder="Your Answer"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  />
                  <button
                    onClick={handleSubmitAnswer}
                    disabled={!answer.trim() || loading}
                    className={`mt-4 w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                      !answer.trim() || loading
                        ? 'bg-blue-300 cursor-not-allowed'
                        : 'bg-blue-600 hover:bg-blue-700'
                    } focus:outline-none`}
                  >
                    Submit Answer
                  </button>
                </div>
              )}

              {/* Submit Status */}
              {submitStatus && (
                <div className="mt-4">
                  <div
                    className={`flex items-center p-4 rounded-md ${
                      submitStatus.type === 'success'
                        ? 'bg-green-100 text-green-700'
                        : submitStatus.type === 'error'
                        ? 'bg-red-100 text-red-700'
                        : 'bg-blue-100 text-blue-700'
                    }`}
                  >
                    {submitStatus.type === 'success' ? (
                      <CheckCircleIcon className="h-6 w-6 mr-2" />
                    ) : submitStatus.type === 'error' ? (
                      <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                    ) : (
                      <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                    )}
                    <span>{submitStatus.message}</span>
                    <button
                      onClick={() => setSubmitStatus(null)}
                      className="ml-auto text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <XCircleIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              )}

              {/* Puzzles Left and Progress */}
              {!currentPuzzle && game && (
                <div className="mt-10">
                  {/* Header with Icon */}
                  <div className="flex items-center mb-4">
                    <svg
                      className="h-6 w-6 text-blue-600 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6 1a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h3 className="text-lg font-medium text-gray-800">Puzzles Progress</h3>
                  </div>

                  {/* Progress Bar */}
                  <div className="w-full bg-gray-200 rounded-full h-4">
                    <div
                      className="bg-blue-600 rounded-full h-4 transition-all duration-500"
                      style={{
                        width: totalPuzzles > 0
                          ? `${((totalPuzzles - puzzlesLeft) / totalPuzzles) * 100}%`
                          : '0%',
                      }}
                      aria-valuenow={totalPuzzles > 0 ? ((totalPuzzles - puzzlesLeft) / totalPuzzles) * 100 : 0}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      role="progressbar"
                    ></div>
                  </div>

                  {/* Puzzles Completed and Left Count */}
                  <div className="flex justify-between mt-2 text-sm text-gray-600">
                    <span>Puzzles Completed: {totalPuzzles - puzzlesLeft}</span>
                    <span>Puzzles Left: {puzzlesLeft}</span>
                  </div>
                </div>
              )}

              {/* Leaderboard */}
              {puzzlesLeft === 0 && leaderboard.length > 0 && (
                <div className="mt-10">
                  <h3 className="text-2xl font-semibold mb-4">Leaderboard</h3>
                  <ul className="space-y-4">
                    {leaderboard.map((entry, index) => (
                      <li key={index} className="flex items-center">
                        <div className="flex-shrink-0">
                          <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-blue-100 text-blue-600">
                            {index + 1}
                          </span>
                        </div>
                        <div className="ml-4">
                          <p className="text-md font-medium text-gray-900">{`${entry.username}`}</p>
                          <p className="text-sm text-gray-500">Points: {entry.points_qr}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={() => navigate('/')}
                    className="mt-6 w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                  >
                    Return to Home
                  </button>
                </div>
              )}

              {/* No Active Game Message */}
              {!game && (
                <div className="mt-10">
                  <div className="flex items-center p-4 rounded-md bg-blue-100 text-blue-700">
                    <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                    <span>No active game is currently available. Please check back later or contact support.</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

QRQuestPage.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    points_qr: PropTypes.number.isRequired,
    // Include other user fields as necessary
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default QRQuestPage;
