import React, { useState } from 'react';
import DropArea from './DropArea';
import FlipCard from './FlipCard';
import PlayButton from './widgets/PlayButton';

const GameArea = () => {
  const [isCardVisible, setCardVisible] = useState(false);
  const [correctShape, setCorrectShape] = useState('Circle'); // The correct shape for this round

  const handleDrop = (item) => {
    if (item.shape === correctShape) {
      setCardVisible(true);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-8">
      {/* Drop Area */}
      <DropArea onDrop={handleDrop}>
        <div className="bg-blue-200 p-6 rounded-lg">Drop the correct shape here!</div>
      </DropArea>

      {/* FlipCard appears when correct shape is dropped */}
      {isCardVisible && (
        <div className="mt-8">
          <FlipCard 
            frontContent={<div className="flex items-center justify-center"><span>Dog</span><PlayButton word="Dog" /></div>} 
            backContent={<div className="flex items-center justify-center"><span>Pies</span><PlayButton word="Pies" /></div>}
          />
        </div>
      )}
    </div>
  );
};

export default GameArea;
