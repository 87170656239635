// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainScreen from './components/MainScreen';
import BingoApp from './components/BingoApp';
import BingoCardGenerator from './components/BingoCardGenerator';
import DatasetsPage from './components/DatasetsPage';
import DatasetDetailPage from './components/DatasetDetailPage';
import CreateDatasetPage from './components/CreateDatasetPage';
import Navbar from './components/Navbar';
import LoginPage from './components/LoginPage';
import PrivateRoute from './auth/PrivateRoute';
import InfoPage from './components/InfoPage';
import GamesPage from './components/GamesPage';
import PressConference from './components/PressConference';
import Dashboard from './components/pages/Dashboard';
import { api } from './auth/auth';
import Unauthorized from './components/Unauthorized';
import SentenceGame from './components/SentenceGame';
import ControlMode from './components/ControlMode';
import FlashcardSection from './components/FlashcardSection';
import SelectVocabularySet from './components/SelectVocabularySet';
import Spinner from './components/Spinner';
import StudentFlashcards from './components/pages/StudentFlashcards';
import FlashcardSetView from './components/FlashcardSetView';
import YoungestLearnersGame from './components/YoungestLearnersGame';
import YoungestLearnersPlay from './components/YoungestLearnersPlay';
import Footer from './components/Footer';
//import SeatAssignment from './components/seat_Assignment/SeatAssignment';
import SeatAssignment from './components/seatAssignment/SeatAssignment'; // Import the new component
import QRQuestPage from './components/pages/QRQuestPage';
import SceneTaskApp from './components/SceneTaskApp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FlashcardGame from './components/FlashcardGame/FlashcardGame';
function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);

  const handleLoginSuccess = (accessToken) => {
    localStorage.setItem('token', accessToken);
    setToken(accessToken);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (token) {
        try {
          const response = await api.get('/users/profile');
          console.log('Fetched user profile:', response.data);
          setUser(response.data); // Store the user data including the role
        } catch (error) {
          console.error('Error fetching user profile:', error);
          handleLogout();
        }
      } else {
        setUser(null);
      }
    };
    fetchUserProfile();
  }, [token]);

  if (token && !user) {
    // Show a loading indicator while fetching user data
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Router>
      <Navbar token={token} onLogout={handleLogout} user={user} />
      <div className="bg-gradient-to-r from-bluey-300 to-bluey-600 min-h-screen flex flex-col">
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
          <Route path="/" element={<MainScreen />} />

          {/* Protected Routes for Admins */}
          <Route
            path="/games"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <GamesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/press-conference"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <PressConference />
              </PrivateRoute>
            }
          />
          <Route
            path="/datasets"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <DatasetsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/scene-task"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user}>
                <SceneTaskApp />
              </PrivateRoute>
            }
          />          
          <Route
            path="/api_bingo/dataset/:fileName"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <DatasetDetailPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/bingo-app"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <BingoApp />
              </PrivateRoute>
            }
          />
          <Route
            path="/card-generator"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <BingoCardGenerator />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-dataset"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <CreateDatasetPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/info"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <InfoPage />
              </PrivateRoute>
            }
          />

          {/* Dashboard Accessible to All Authenticated Users */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user}>
                <Dashboard user={user} />
              </PrivateRoute>
            }
          />

          {/* Flashcards Routes for Students */}
          <Route
            path="/student-flashcards"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['student']}>
                <StudentFlashcards user={user} /> {/* Pass user object to StudentFlashcards */}
              </PrivateRoute>
            }
          />
          <Route
            path="/qr-quest"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['student']}>
                <QRQuestPage user={user} token={token} />
              </PrivateRoute>
            }
          />
          {/* Vocabulary Set Selection */}
          <Route
            path="/select-vocab-set"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['student']}>
                <SelectVocabularySet />
              </PrivateRoute>
            }
          />

          {/* Flashcards Practice */}
          <Route
            path="/flashcards/:vocabSetId"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['student']}>
                <FlashcardSetView />
              </PrivateRoute>
            }
          />

          {/* Unauthorized Route */}
          <Route path="/unauthorized" element={<Unauthorized />} />

          {/* Student Game Route */}
          <Route
            path="/sentence-game"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['student']}>
                <SentenceGame />
              </PrivateRoute>
            }
          />

          {/* Youngest Learners Game and Play Routes */}
          <Route
            path="/youngest-learners"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <YoungestLearnersGame />
              </PrivateRoute>
            }
          />
          <Route
            path="/youngest-learners/play"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <YoungestLearnersPlay />
              </PrivateRoute>
            }
          />

          {/* Control Mode Route */}
          <Route
            path="/control-mode"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} requiredRoles={['admin']}>
                <ControlMode />
              </PrivateRoute>
            }
          />

          {/* Seat Assignment Route */}
          <Route
            path="/seat-assignment"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user}>
                <SeatAssignment />
              </PrivateRoute>
            }
          />
          <Route
            path="/flashcard-game"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user} >
                <FlashcardGame />
              </PrivateRoute>
            }
          />          
        </Routes>
        <ToastContainer 
        position="top-right" 
        autoClose={3000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />        
      </div>
      <Footer />
    </Router>

    
  );
}

export default App;
