// src/components/FlashcardGame/FlashcardGame.js

import React, { useState, useEffect } from 'react';
import FlashcardSlider from './FlashcardSlider';
import Flashcard from './Flashcard';
import PlayButton from '../widgets/PlayButton';
const FlashcardGame = () => {
  // Initialize the list of images
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);

  // Fetch images from the public folder on component mount
  useEffect(() => {
    // Assuming images are in public/img/game and named like 'cow.jpeg', 'ship.jpeg', etc.
    const fetchImages = () => {
        const imageNames = [
            'apple.jpeg',
            'cloud.jpeg',
            'jump.jpeg',
            'pencil.jpeg',
            'star.jpeg',
            'ball.jpeg',
            'computer.jpeg',
            'kangaroo.jpeg',
            'picture.jpeg',
            'sun.jpeg',
            'bathroom.jpeg',
            'cow.jpeg',
            'king.jpeg',
            'pink.jpeg',
            'table.jpeg',
            'bed.jpeg',
            'crayon.jpeg',
            'kite.jpeg',
            'plane.jpeg',
            'train.jpeg',
            'bike.jpeg',
            'desk.jpeg',
            'lamp.jpeg',
            'rice.jpeg',
            'tree.jpeg',
            'bird.jpeg',
            'dog.jpeg',
            'lion.jpeg',
            'river.jpeg',
            'umbrella.jpeg',
            'book.jpeg',
            'doll.jpeg',
            'map.jpeg',
            'robot.jpeg',
            'water.jpeg',
            'brown.jpeg',
            'duck.jpeg',
            'milk.jpeg',
            'sandwich.jpeg',
            'white.jpeg',
            'car.jpeg',
            'egg.jpeg',
            'money.jpeg',
            'ship.jpeg',
            'zebra.jpeg',
            'chair.jpeg',
            'flower.jpeg',
            'moon.jpeg',
            'shoes.jpeg',
            'chicken.jpeg',
            'giraffe.jpeg',
            'noodles.jpeg',
            'sky.jpeg',
            'climb.jpeg',
            'goat.jpeg',
            'panda.jpeg',
            'snow.jpeg',
            'closet.jpeg',
            'juice.jpeg',
            'pen.jpeg',
            'socks.jpeg'
          ];
          
      const imagePaths = imageNames.map(name => `/img/game/${name}`);
      setImages(imagePaths);
    };

    fetchImages();
  }, []);

  // Function to handle flipping the big card
  const handleFlip = () => {
    if (images.length === 0) return;

    // Select a random image from the list
    const randomIndex = Math.floor(Math.random() * images.length);
    const selectedImage = images[randomIndex];
    setCurrentImage(selectedImage);
  };

  // Function to handle clicking "Next"
  const handleNext = () => {
    if (!currentImage) return;

    // Remove the current image from the list
    const updatedImages = images.filter(img => img !== currentImage);
    setImages(updatedImages);

    // Reset the current image
    setCurrentImage(null);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-600 p-4">
      <h1 className="text-4xl font-light mb-6 text-white">Flashcard Game</h1>

      {/* FlashcardSlider: Shows a back-side pattern for each image */}
      <FlashcardSlider images={images} />

      {/* Big Flashcard: Shows a default back-side pattern or the selected image */}
      <div className="my-6">
      <PlayButton word={currentImage ? currentImage.split('/').pop().split('.')[0] : ''} />
        <Flashcard
          image={currentImage}
          onFlip={handleFlip}
          onNext={handleNext}
          hasImage={!!currentImage}
        />
      </div>
      
      {/* Optional: Display remaining count */}
      <div className='bg-slate-200 p-4 rounded-xl text-blue-950'>
        <p>Images Left: <span className='text-2xl'>{images.length}</span></p>
      </div>

      {/* Optional: Message when no flashcards are left */}
      {images.length === 0 && (
        <div className="mt-4 text-green-600">
          <p>Congratulations! You've gone through all the flashcards.</p>
        </div>
      )}
    </div>
  );
};

export default FlashcardGame;
