// src/components/seatAssignment/SeatAssignment.js

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaCog, FaRedo, FaChair, FaTrash } from 'react-icons/fa';

// Ensure accessibility for react-modal
Modal.setAppElement('#root');

// Unique ID generator
const generateUniqueId = (() => {
  let id = Date.now();
  return () => id++;
})();

// Initial data
const defaultStudents = [
  { id: generateUniqueId(), name: "Maja" },
  { id: generateUniqueId(), name: "Iza" },
  { id: generateUniqueId(), name: "Stanley" },
  { id: generateUniqueId(), name: "Franek" },
  { id: generateUniqueId(), name: "Kuba" },
  { id: generateUniqueId(), name: "Franek" },
  { id: generateUniqueId(), name: "Staś" },
  { id: generateUniqueId(), name: "Emila" }
];

const seatColors = {
  "Blue": "bg-blue-500",
  "Red": "bg-red-500",
  "Green": "bg-green-500",
  "Yellow": "bg-yellow-500",
  "Black": "bg-black",
  "White": "bg-white text-black border",
  "Pink": "bg-pink-500",
  "Purple": "bg-purple-500",
};

const initialSeats = ["Blue", "Red", "Green", "Yellow", "Black", "White", "Pink", "Purple"];

const SeatAssignment = () => {

  // State management
  const [students, setStudents] = useState([]);
  const [seats, setSeats] = useState([]);
  const [seatAssignments, setSeatAssignments] = useState({});
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [newPresetName, setNewPresetName] = useState('');
  const [presetStudents, setPresetStudents] = useState([]);
  const [presets, setPresets] = useState([]);
  const [studentPresence, setStudentPresence] = useState({});
  const [presetToDelete, setPresetToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPresetIndex, setCurrentPresetIndex] = useState(0);

  // Load presets from localStorage on component mount
  useEffect(() => {
    const storedPresets = JSON.parse(localStorage.getItem('seatPresets')) || [];
    if (storedPresets.length === 0) {
      // Initialize with default preset
      const defaultPreset = {
        name: 'Default',
        students: defaultStudents
      };
      localStorage.setItem('seatPresets', JSON.stringify([defaultPreset]));
      setPresets([defaultPreset]);
      setStudents(defaultPreset.students.map(student => ({ ...student })));
    } else {
      setPresets(storedPresets);
      setStudents(storedPresets[0].students.map(student => ({ ...student })));
    }
    setSeats([...initialSeats]);
  }, []);

  // Update presence when students list changes
  useEffect(() => {
    const initialPresence = {};
    students.forEach((student) => {
      initialPresence[student.id] = true; // All students are present by default
    });
    setStudentPresence(initialPresence);
  }, [students]);

  // Function to toggle student presence
  const toggleStudentPresence = (studentId) => {
    setStudentPresence((prev) => ({
      ...prev,
      [studentId]: !prev[studentId],
    }));
  };

  // Function to assign a seat to a student
  const assignSeat = (studentId) => {
    if (!studentPresence[studentId]) return;  // Student is absent
    if (seatAssignments[studentId]) return;  // Already assigned

    if (seats.length === 0) {
      alert("No more seats available.");
      return;
    }

    const randomIndex = Math.floor(Math.random() * seats.length);
    const selectedSeat = seats[randomIndex];
    setSeatAssignments(prev => ({ ...prev, [studentId]: selectedSeat }));
    setSeats(prev => prev.filter(seat => seat !== selectedSeat));
  };

  // Function to assign seats to all present students
  const assignAllSeats = () => {
    const availableSeats = [...seats];
    const presentStudents = students.filter((student) => studentPresence[student.id]);

    if (presentStudents.length > availableSeats.length) {
      alert('Not enough seats for all present students.');
      return;
    }

    const newAssignments = { ...seatAssignments };
    presentStudents.forEach((student) => {
      if (!seatAssignments[student.id]) {
        const randomIndex = Math.floor(Math.random() * availableSeats.length);
        const selectedSeat = availableSeats.splice(randomIndex, 1)[0];
        newAssignments[student.id] = selectedSeat;
      }
    });

    setSeatAssignments(newAssignments);
    setSeats(availableSeats);
  };

  // Function to reset all assignments
  const resetAssignments = () => {
    setSeatAssignments({});
    setSeats([...initialSeats]);
    setIsResetModalOpen(false);
  };

  // Function to open settings modal
  const openSettings = () => {
    // Initialize presetStudents with a deep copy of current students
    setPresetStudents(students.map(student => ({ ...student })));
    setNewPresetName(presets[currentPresetIndex]?.name || '');
    setIsSettingsOpen(true);
  };

  // Function to close settings modal
  const closeSettings = () => {
    setIsSettingsOpen(false);
  };

  // Function to save a new or existing preset
  const savePreset = () => {
    const trimmedName = newPresetName.trim();

    if (!trimmedName || presetStudents.length === 0) {
      alert("Preset name and student list cannot be empty.");
      return;
    }

    // Trim names and filter out empty names
    const studentsWithTrimmedNames = presetStudents
      .map(student => ({ ...student, name: student.name.trim() }))
      .filter(student => student.name !== '');

    const newPreset = {
      name: trimmedName,
      students: studentsWithTrimmedNames
    };

    let updatedPresets;
    if (currentPresetIndex !== null && currentPresetIndex < presets.length) {
      // Update existing preset
      updatedPresets = presets.map((preset, index) =>
        index === currentPresetIndex ? newPreset : preset
      );
    } else {
      // Add new preset
      updatedPresets = [...presets, newPreset];
    }

    setPresets(updatedPresets);
    localStorage.setItem('seatPresets', JSON.stringify(updatedPresets));

    // Reset seats and assignments
    setStudents(studentsWithTrimmedNames.map(student => ({ ...student })));
    setSeatAssignments({});
    setSeats([...initialSeats]);

    // Reset modal fields
    setNewPresetName('');
    setPresetStudents([]);
    setCurrentPresetIndex(updatedPresets.length - 1);

    closeSettings();
  };

  // Function to load a preset
  const loadPreset = (presetIndex) => {
    setStudents(presets[presetIndex].students.map(student => ({ ...student })));
    setSeatAssignments({});
    setSeats([...initialSeats]);
    setCurrentPresetIndex(presetIndex);
  };

  // Function to add a new student to the preset
  const addStudentToPreset = () => {
    setPresetStudents((prev) => [
      ...prev,
      { id: generateUniqueId(), name: '' }
    ]);
  };

  // Function to remove a student from the preset
  const removeStudentFromPreset = (studentId) => {
    setPresetStudents((prev) => prev.filter((student) => student.id !== studentId));
  };

  // Function to update a student's name in the preset
  const updateStudentNameInPreset = (studentId, newName) => {
    setPresetStudents((prev) =>
      prev.map((student) =>
        student.id === studentId ? { ...student, name: newName } : student
      )
    );
  };

  // Function to initiate delete preset
  const initiateDeletePreset = (presetIndex) => {
    setPresetToDelete(presetIndex);
    setIsDeleteModalOpen(true);
  };

  // Function to cancel delete preset
  const cancelDeletePreset = () => {
    setPresetToDelete(null);
    setIsDeleteModalOpen(false);
  };

  // Function to confirm delete preset
  const confirmDeletePreset = () => {
    if (presetToDelete !== null) {
      const updatedPresets = presets.filter((_, index) => index !== presetToDelete);
      setPresets(updatedPresets);
      localStorage.setItem('seatPresets', JSON.stringify(updatedPresets));

      // If deleted preset is the current one, load default or first preset
      if (currentPresetIndex === presetToDelete) {
        if (updatedPresets.length > 0) {
          loadPreset(0);
          setCurrentPresetIndex(0);
        } else {
          // Reset to default if no presets are left
          setStudents([]);
          setSeatAssignments({});
          setSeats([...initialSeats]);
          setCurrentPresetIndex(null);
        }
      } else if (currentPresetIndex > presetToDelete) {
        setCurrentPresetIndex((prev) => prev - 1);
      }

      setPresetToDelete(null);
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-4">
      {/* Header */}
      <div className="w-full flex flex-col md:flex-row justify-between items-center mb-4">
        <h1 className="text-3xl font-bold mb-4 md:mb-0">Seat Assignment App</h1>
        <div className="flex space-x-2">
          <button
            onClick={() => setIsResetModalOpen(true)}
            className="text-red-500 hover:text-red-700"
            title="Reset Assignments"
          >
            <FaRedo size={24} />
          </button>
          <button
            onClick={openSettings}
            className="text-blue-500 hover:text-blue-700"
            title="Settings"
          >
            <FaCog size={24} />
          </button>
        </div>
      </div>

      {/* Preset Selector */}
      <div className="mb-4 flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
        <div className="flex items-center">
          <label htmlFor="preset-select" className="mr-2 font-semibold">Select Preset:</label>
          <select
            id="preset-select"
            value={currentPresetIndex !== null ? currentPresetIndex : ''}
            onChange={(e) => loadPreset(parseInt(e.target.value))}
            className="border border-gray-300 rounded px-2 py-1"
          >
            {presets.map((preset, index) => (
              <option key={index} value={index}>{preset.name}</option>
            ))}
          </select>
          <button
            onClick={() => initiateDeletePreset(currentPresetIndex)}
            className="ml-2 text-red-500 hover:text-red-700"
            title="Delete Preset"
            disabled={presets.length <= 1}
          >
            <FaTrash size={20} />
          </button>
        </div>
        <button
          onClick={assignAllSeats}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 flex items-center"
        >
          <FaChair className="mr-2" /> Assign Seats to All
        </button>
      </div>

      {/* Main Content */}
      <div className="flex flex-col md:flex-row w-full px-8">
        {/* Student Buttons */}
        <div className="w-full md:w-1/4 mb-4 md:mb-0 px-4">
          <h2 className="text-xl font-semibold mb-2">Students</h2>
          <div className="flex flex-col space-y-2">
            {students.map((student) => (
              <div key={student.id} className="flex items-center">
                <input
                  type="checkbox"
                  checked={studentPresence[student.id]}
                  onChange={() => toggleStudentPresence(student.id)}
                  className="mr-2"
                />
                <button
                  onClick={() => assignSeat(student.id)}
                  disabled={seatAssignments[student.id] || !studentPresence[student.id]}
                  className={`flex-1 px-4 py-2 rounded text-white ${
                    seatAssignments[student.id]
                      ? 'bg-gray-400 cursor-not-allowed'
                      : studentPresence[student.id]
                        ? 'bg-blue-500 hover:bg-blue-700'
                        : 'bg-gray-300 cursor-not-allowed'
                  }`}
                >
                  {student.name}
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Seat Layout */}
        <div className="w-full md:w-3/4">
          <h2 className="text-xl font-semibold mb-2">Seat Layout</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {initialSeats.map((seat, index) => {
              const assignedStudent = students.find(
                (student) => seatAssignments[student.id] === seat
              );
              const isAssigned = !!assignedStudent;
              const bgColor = seatColors[seat];
              const textColor = seat === "White" ? "text-black" : "text-white";

              return (
                <div
                  key={index}
                  className={`${bgColor} ${textColor} flex items-center justify-center rounded-lg shadow-lg h-24`}
                >
                  <div className="text-center">
                    <p className="font-bold">{seat} Seat</p>
                    {isAssigned && <p className="text-sm">Assigned to {assignedStudent.name}</p>}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Seat Assignments */}
      <div className="w-full mt-6">
        <h2 className="text-xl font-semibold mb-2">Seat Assignments</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {Object.entries(seatAssignments).map(([studentId, seat]) => {
            const student = students.find((s) => s.id === parseInt(studentId));
            const bgColor = seatColors[seat];
            const textColor = seat === "White" ? "text-black" : "text-white";

            return (
              <div
                key={studentId}
                className={`${bgColor} ${textColor} flex items-center justify-center rounded-lg shadow-lg h-24`}
              >
                <p className="font-bold">{student.name} ➔ {seat}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Reset Confirmation Modal */}
      <Modal
        isOpen={isResetModalOpen}
        onRequestClose={() => setIsResetModalOpen(false)}
        contentLabel="Confirm Reset"
        className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20 outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-bold mb-4">Confirm Reset</h2>
        <p className="mb-6">Are you sure you want to reset all seat assignments?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setIsResetModalOpen(false)}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={resetAssignments}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
          >
            Confirm
          </button>
        </div>
      </Modal>

      {/* Delete Preset Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={cancelDeletePreset}
        contentLabel="Confirm Delete Preset"
        className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20 outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-bold mb-4">Delete Preset</h2>
        <p className="mb-6">
          Are you sure you want to delete the preset "
          {presetToDelete !== null && presets[presetToDelete]?.name}"?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={cancelDeletePreset}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={confirmDeletePreset}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
          >
            Delete
          </button>
        </div>
      </Modal>

      {/* Settings Modal */}
      <Modal
        isOpen={isSettingsOpen}
        onRequestClose={closeSettings}
        contentLabel="Settings"
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto mt-10 outline-none overflow-y-auto max-h-screen"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-bold mb-4">Settings</h2>
        {/* Preset Name */}
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">Preset Name:</label>
          <input
            type="text"
            value={newPresetName}
            onChange={(e) => setNewPresetName(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded"
            placeholder="Enter preset name"
          />
        </div>
        {/* Student List Management */}
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">Students:</label>
          <div className="space-y-2">
            {presetStudents.map((student) => (
              <div key={student.id} className="flex items-center">
                <input
                  type="text"
                  value={student.name}
                  onChange={(e) => updateStudentNameInPreset(student.id, e.target.value)}
                  className="flex-1 border border-gray-300 p-2 rounded mr-2"
                  placeholder="Enter student name"
                />
                <button
                  onClick={() => removeStudentFromPreset(student.id)}
                  className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-700"
                  title="Remove Student"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              onClick={addStudentToPreset}
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Add Student
            </button>
          </div>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={closeSettings}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Close
          </button>
          <button
            onClick={savePreset}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
          >
            Save Preset
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SeatAssignment;
