// src/components/DashboardCard.jsx

import React from 'react';
import { Link } from 'react-router-dom';

const DashboardCard = ({
  to,
  Icon,
  title,
  description,
  borderColorClass = 'border-blue-500',
  iconColorClass = 'text-blue-500',
}) => {
  return (
    <Link to={to}>
      <div
        className={`bg-white ${borderColorClass} border-l-4 rounded-lg shadow-md p-6 flex flex-col justify-between h-full transition duration-300 hover:shadow-lg`}
      >
        {/* Top Bar with Icon and Title */}
        <div className="flex items-center mb-4">
          <Icon className={`${iconColorClass} text-4xl mr-3`} />
          <h4 className="text-xl font-semibold text-gray-800">{title}</h4>
        </div>
        {/* Description */}
        <p className="text-sm text-gray-600">{description}</p>
      </div>
    </Link>
  );
};

export default DashboardCard;
