// src/components/FlashcardGame/Flashcard.js

import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { FaSyncAlt, FaArrowRight } from 'react-icons/fa';


const Flashcard = ({ image, onFlip, onNext, hasImage }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
    if (!isFlipped) {
      onFlip();
    }
  };

  const handleNextClick = () => {
    setIsFlipped(false);
    onNext();
  };

  return (
    <div className="flex flex-col items-center">
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        {/* Back of the card */}
        <div
          className="w-96 h-96 bg-blue-600 rounded-lg shadow-lg flex items-center justify-center cursor-pointer transform hover:scale-105 transition-transform"
          onClick={handleCardClick}
        >
          <FaSyncAlt className="text-4xl text-white" />
        </div>

        {/* Front of the card */}
        <div
          className="w-96 h-96 bg-white rounded-lg shadow-lg cursor-pointer overflow-hidden flex flex-col"
          onClick={handleCardClick}
        >
          {hasImage ? (
            <img
              src={image}
              alt="Flashcard Front"
              className="w-full h-5/6 object-cover"
              loading="lazy"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/img/game/back_pattern.jpeg'; // Fallback image
              }}
            />
          ) : (
            <div className="w-full h-5/6 bg-gray-100 flex items-center justify-center">
              <p className="text-gray-500">No Image</p>
            </div>
          )}
              {/* Play Button, pass the image file name as a name, withough .jpeg */}

              


             {/* Next Button */}
          <button
            onClick={handleNextClick}
            className="mt-auto bg-blue-500 text-white px-4 py-4 rounded-b-lg hover:bg-blue-600 flex items-center justify-center text-xl"
          >
            Next <FaArrowRight className="ml-2" />
          </button>
        </div>
      </ReactCardFlip>
    </div>
  );
};

export default Flashcard;
