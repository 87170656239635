// src/components/FlashcardSetView.jsx

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import FlashcardDeck from './FlashcardDeck';
import { FaRedo, FaArrowLeft } from 'react-icons/fa';
import Spinner from './Spinner';
import { api } from '../auth/auth';

const FlashcardSetView = () => {
  const { vocabSetId } = useParams();
  const [flashcards, setFlashcards] = useState([]);
  const [vocabSetName, setVocabSetName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');


  useEffect(() => {
    const fetchSetDetails = async () => {
      setLoading(true);
      try {
        // Fetch vocabulary set details
        const vocabSetResponse = await api.get(`/flashcards/vocabulary-sets/${vocabSetId}/flashcards/`);
        setVocabSetName(vocabSetResponse.data.name || 'Vocabulary Set');
  
        // Fetch flashcards for the vocabulary set
        const flashcardsResponse = await api.get(`/flashcards/vocabulary-sets/${vocabSetId}/flashcards/`);
        setFlashcards(flashcardsResponse.data || []);
  
        setLoading(false);
      } catch (err) {
        console.error('Error fetching set details:', err);
        setError('Failed to load flashcards.');
        setLoading(false);
      }
    };
  
    fetchSetDetails();
  }, [vocabSetId]);
  

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-100 to-purple-100">
        <Spinner />
        <p className="mt-4 text-lg sm:text-xl text-blue-600">Loading flashcards...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-red-100 to-pink-100 p-4">
        <div className="flex flex-col items-center bg-red-200 p-8 rounded-lg shadow-lg">
          <FaRedo className="text-red-600 text-4xl mb-4" />
          <h2 className="text-2xl sm:text-3xl font-semibold text-red-700 mb-2">Error</h2>
          <p className="text-center text-red-600">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 flex items-center text-blue-600 hover:text-blue-800"
          >
            <FaRedo className="mr-2" />
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (flashcards.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-yellow-100 to-orange-100">
        <div className="flex flex-col items-center bg-yellow-200 p-8 rounded-lg shadow-lg">
          <FaRedo className="text-yellow-600 text-4xl mb-4" />
          <h2 className="text-2xl sm:text-3xl font-semibold text-yellow-700 mb-2">No Flashcards Available</h2>
          <p className="text-center text-yellow-600">There are no flashcards available for this set.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-8">
      <div className="container mx-auto px-4">
        <div className='min-w-full flex justify-center'>
          <div className="mb-8 text-center rounded-xl bg-gray-100 max-w-fit p-6">
            <h2 className="text-xl sm:text-2xl font-bold text-indigo-700 mb-2 flex items-center justify-center">
              {vocabSetName}
            </h2>
            <p className="text-base sm:text-base text-indigo-600">Swipe through the flashcards to practice.</p>
          </div>
        </div>
        {/* Display the FlashcardDeck */}
        <div className="mb-12">
          <FlashcardDeck flashcards={flashcards} />
        </div>

        <div className="mt-8 flex justify-center">
          <Link
            to="/student-flashcards"
            className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-md shadow hover:bg-blue-700 transition duration-300 flex items-center"
          >
            <FaArrowLeft className="mr-2" />
            Change Set
          </Link> 
        </div>
      </div>
    </div>
  );
};

export default FlashcardSetView;
