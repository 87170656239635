import React, { useState } from 'react';
import { AiOutlineSound } from 'react-icons/ai';
import PlayButton from './widgets/PlayButton'; // Import PlayButton for sound functionality

const FlipCard = ({ frontContent, backContent, frontWord, backWord }) => {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <div
      className={`w-64 h-64 bg-white rounded-lg shadow-lg flex items-center justify-center transition-transform duration-500 ${
        flipped ? 'rotate-y-180' : ''
      }`}
      style={{ perspective: '1000px' }}
    >
      <div className="w-full h-full relative" style={{ transformStyle: 'preserve-3d' }}>
        {/* Front Side */}
        <div
          className={`absolute w-full h-full bg-white p-4 flex flex-col justify-center items-center ${
            flipped ? 'hidden' : 'block'
          }`}
          style={{ backfaceVisibility: 'hidden' }}
        >
          <div className="relative">
            {/* Bigger sound icon */}
            <PlayButton word={frontWord} size={48} className="text-blue-600 mb-4 absolute top-0 left-0" />
            <p className="text-xl font-bold text-gray-800 text-center mb-4 mt-8">{frontContent}</p>
          </div>
          {/* Flip button */}
          <button
            onClick={handleFlip}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg font-semibold mt-4"
          >
            Flip
          </button>
        </div>

        {/* Back Side */}
        <div
          className={`absolute w-full h-full bg-gray-200 p-4 flex flex-col justify-center items-center transform rotate-y-180 ${
            flipped ? 'block' : 'hidden'
          }`}
          style={{ backfaceVisibility: 'hidden' }}
        >
          <div className="relative">
            {/* Bigger sound icon */}
            <PlayButton word={backWord} size={48} className="text-blue-600 mb-4 absolute top-0 left-0" />
            <p className="text-xl font-bold text-gray-800 text-center mb-4 mt-8">{backContent}</p>
          </div>
          {/* Flip Back button */}
          <button
            onClick={handleFlip}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg font-semibold mt-4"
          >
            Flip Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
