// src/components/AdminDashboard.jsx

import React from 'react';
import {
  FaThLarge,
  FaGamepad,
  FaTable,
  FaChalkboardTeacher,
  FaChild,
  FaInfoCircle,
  FaTablet,
} from 'react-icons/fa';
import { TbCards } from "react-icons/tb";
import DashboardCard from '../widgets/DashboardCard';

const AdminDashboard = () => {
  const cards = [
    {
      to: '/card-generator',
      Icon: FaThLarge,
      title: 'Card Generator',
      description: 'Create custom cards for various activities.',
      borderColorClass: 'border-blue-500',
      iconColorClass: 'text-blue-500',
    },
    {
      to: '/bingo-app',
      Icon: FaGamepad,
      title: 'Bingo App',
      description: 'Engaging Bingo activities for students.',
      borderColorClass: 'border-purple-500',
      iconColorClass: 'text-purple-500',
    },
    {
      to: '/datasets',
      Icon: FaTable,
      title: 'Datasets',
      description: 'Manage vocabulary and datasets.',
      borderColorClass: 'border-green-500',
      iconColorClass: 'text-green-500',
    },
    {
      to: '/control-mode',
      Icon: FaChalkboardTeacher,
      title: 'Control Center',
      description: 'Manage application settings and assignments.',
      borderColorClass: 'border-indigo-500',
      iconColorClass: 'text-indigo-500',
    },
    {
      to: '/flashcard-game',
      Icon: TbCards,
      title: 'Flashcard Game',
      description: 'Practive vocabulary with flashcards.',
      borderColorClass: 'border-indigo-500',
      iconColorClass: 'text-indigo-500',
    },    
    {
      to: '/scene-task',
      Icon: FaTablet,
      title: 'A Bit of Acting',
      description: 'Real-life scenarios for short conversations.',
      borderColorClass: 'border-pink-500',
      iconColorClass: 'text-pink-500',
    },
    {
      to: '/youngest-learners',
      Icon: FaChild,
      title: 'Youngest Learners Game',
      description: 'Interactive game for 7-8 year olds.',
      borderColorClass: 'border-teal-500',
      iconColorClass: 'text-teal-500',
    },
    {
      to: '/seat-assignment',
      Icon: FaChild, // Consider using a different icon for variety
      title: 'Seat Assignment',
      description: 'Assign seats interactively.',
      borderColorClass: 'border-orange-500',
      iconColorClass: 'text-orange-500',
    },
    {
      to: '/info',
      Icon: FaInfoCircle,
      title: 'Info',
      description: 'Access informational resources.',
      borderColorClass: 'border-sky-500',
      iconColorClass: 'text-sky-500',
    },
  ];

  return (
    <div className="container mx-auto p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {cards.map((card, index) => (
          <DashboardCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
