import React from 'react';
import { Link } from 'react-router-dom';
import { FaBook, FaClipboardList, FaLanguage } from 'react-icons/fa';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
const StudentDashboard = ({ user }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {/* Sentence Builder */}
      <Link to="/sentence-game">
        <div className="bg-green-700 hover:bg-green-800 text-white rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
          <FaBook className="text-4xl" />
          <div>
            <h4 className="text-xl font-semibold">Sentence Builder</h4>
            <p className="text-sm">Build sentences using correct grammar.</p>
          </div>
        </div>
      </Link>

      {/* Flashcards */}
      <Link to="/student-flashcards">
        <div className="bg-purple-700 hover:bg-purple-800 text-white rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
          <FaClipboardList className="text-4xl" />
          <div>
            <h4 className="text-xl font-semibold">Flashcards</h4>
            <p className="text-sm">Practice vocabulary with flashcards.</p>
          </div>
        </div>
      </Link>
      {/* Twist the Tongue */}
      <Link to="/qr-quest">
        <div className="bg-sky-800 hover:bg-sky-900 text-white rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
          <QrCodeScannerIcon className="text-4xl" />
          <div>
            <h4 className="text-xl font-semibold">QR Quest</h4>
            <p className="text-sm">Hunt down all of them!</p>
          </div>
        </div>
      </Link>
      {/* Twist the Tongue */}
      <Link to="/twist-the-tongue">
        <div className="bg-yellow-500 hover:bg-yellow-600 text-white rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
          <FaLanguage className="text-4xl" />
          <div>
            <h4 className="text-xl font-semibold">Twist the Tongue</h4>
            <p className="text-sm">Improve pronunciation and fluency.</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default StudentDashboard;
