// src/components/BingoApp.js

import React, { useState, useEffect, useRef } from 'react';
import { parse } from 'papaparse';
import VerbFormSelector from './VerbFormSelector';
import DrawButton from './DrawButton';
import Timer from './Timer';
import PlayButton from './widgets/PlayButton';
import jsPDF from 'jspdf';
import { api } from '../auth/auth'; // Adjusted import
import { toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

function BingoApp() {
  const [datasets, setDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState('');
  const [columns, setColumns] = useState([]);
  const [selectedForm, setSelectedForm] = useState('');
  const [dataRows, setDataRows] = useState([]);
  const [availableWords, setAvailableWords] = useState([]);
  const [drawnWords, setDrawnWords] = useState([]);
  const [timerSeconds, setTimerSeconds] = useState(15);
  const [gameStarted, setGameStarted] = useState(false);
  const [drawCounter, setDrawCounter] = useState(1);
  const [showModeSelector, setShowModeSelector] = useState(true); // New state

  const popupWindowRef = useRef(null);

  const fetchDatasets = async () => {
    try {
      const response = await api.get('/api_bingo/datasets/');
      setDatasets(response.data.datasets);
    } catch (error) {
      console.error('Error fetching datasets:', error);
      toast.error('Failed to fetch datasets.');
    }
  };

  useEffect(() => {
    fetchDatasets();
  }, []);

  // Load the selected dataset and extract columns
  useEffect(() => {
    if (selectedDataset) {
      api
        .get(`/api_bingo/datasets/${selectedDataset}/`, { responseType: 'blob' })
        .then((response) => response.data.text())
        .then((data) => {
          parse(data, {
            header: true,
            complete: (result) => {
              setDataRows(result.data);
              setColumns(result.meta.fields);
              setSelectedForm(''); // Reset the selected form
              setShowModeSelector(true); // Show mode selector upon new dataset
            },
          });
        })
        .catch((error) => {
          console.error('Error loading dataset:', error);
          toast.error('Failed to load selected dataset.');
        });
    } else {
      setColumns([]);
      setDataRows([]);
      setSelectedForm('');
      setShowModeSelector(true); // Reset visibility when no dataset is selected
    }
  }, [selectedDataset]);

  const startGame = () => {
    if (!selectedForm) {
      toast.warning('Please select a mode to start the game.');
      return;
    }

    const confirmation = window.confirm('Are you sure you want to start a new game? This will reset the current game.');

    if (confirmation) {
      const shuffledWords = [...dataRows]
        .sort(() => Math.random() - 0.5)
        .map((row) => row[selectedForm])
        .filter(Boolean);

      setAvailableWords(shuffledWords);
      setDrawnWords([]);
      setGameStarted(true);
      setDrawCounter(1);
      setShowModeSelector(false); // Hide mode selector

      if (popupWindowRef.current && !popupWindowRef.current.closed) {
        popupWindowRef.current.close();
        popupWindowRef.current = null;
      }

      toast.success('Game started successfully!');
    }
  };

  const handleDrawWord = () => {
    if (availableWords.length === 0) {
      toast.info('No more words to draw!');
      return;
    }

    const drawnWord = availableWords[0];

    // Prepend the new word to show the last drawn on top
    setAvailableWords(availableWords.slice(1));
    setDrawnWords([{ word: drawnWord, drawOrder: drawCounter }, ...drawnWords]);
    setDrawCounter(drawCounter + 1);

    if (!popupWindowRef.current || popupWindowRef.current.closed) {
      popupWindowRef.current = window.open('', 'DrawnWordWindow', 'width=600,height=400');
      popupWindowRef.current.onbeforeunload = () => {
        popupWindowRef.current = null;
      };
    }

    if (popupWindowRef.current) {
      const popupDocument = popupWindowRef.current.document;
      popupDocument.open();
      popupDocument.write(`
        <html>
          <head>
            <title>Drawn Word</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
                background-color: #f0f4f8;
              }
              h1 {
                font-size: 5rem;
                color: #333;
              }
            </style>
          </head>
          <body>
            <h1>${drawnWord}</h1>
          </body>
        </html>
      `);
      popupDocument.close();
    }

    toast.success(`Word drawn: ${drawnWord}`);
  };

  const handleSaveAsPDF = () => {
    if (drawnWords.length === 0) {
      toast.warning('No words have been drawn yet!');
      return;
    }

    const doc = new jsPDF();

    // Add the title
    doc.setFontSize(20);
    doc.text('Bingo Game Results', 105, 20, null, null, 'center');

    // Add date and time
    const currentDate = new Date();
    doc.setFontSize(12);
    doc.text(`Date: ${currentDate.toLocaleDateString()}`, 14, 30);
    doc.text(`Time: ${currentDate.toLocaleTimeString()}`, 14, 37);

    // Add game mode
    doc.text(`Game Mode: ${selectedForm}`, 14, 44);

    // Add drawn words
    doc.text('Drawn Words:', 14, 54);
    let yPosition = 64;
    drawnWords.forEach(({ word, drawOrder }) => {
      doc.text(`${drawOrder}. ${word}`, 14, yPosition);
      yPosition += 7;
      if (yPosition > 280) {
        doc.addPage();
        yPosition = 20;
      }
    });

    // Save the PDF
    doc.save(`Bingo_Game_Results_${currentDate.toISOString()}.pdf`);
    toast.success('Results saved as PDF!');
  };

  // Toggle function for mode selector
  const toggleModeSelector = () => {
    setShowModeSelector(!showModeSelector);
  };

  return (
    <div className="min-h-screen p-10 flex flex-col items-center bg-blue-100">
      <h1 className="text-4xl font-bold mb-6 text-sky-800">Bingo Game</h1>

      {/* Selection and Controls */}
      <div             className={`transition-all duration-300 overflow-hidden ${
              showModeSelector ? 'opacity-100 max-h-screen w-full' : 'opacity-0 max-h-0'
            }`}
            >

           
      <div className="w-full max-w-4xl mb-8 bg-white shadow-lg rounded-lg p-6 flex flex-col md:flex-row justify-between items-start gap-6">
        {/* Dataset and Mode Selection */}
        <div className="flex-1 flex flex-col gap-4">
          {/* Dataset selection */}
          <div>
            <label htmlFor="datasetSelector" className="block text-lg font-semibold text-gray-700 mb-2">
              Select Dataset:
            </label>
            <select
              id="datasetSelector"
              value={selectedDataset}
              onChange={(e) => setSelectedDataset(e.target.value)}
              className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring focus:ring-sky-300"
            >
              <option value="">--Select Dataset--</option>
              {datasets.map((dataset) => (
                <option key={dataset.file_name} value={dataset.file_name}>
                  {dataset.display_name}
                </option>
              ))}
            </select>
          </div>

          {/* Mode (column) selection with transition */}
          <div

          >
            {columns.length > 0 && showModeSelector && (
              <div>
                <VerbFormSelector options={columns} setSelectedForm={setSelectedForm} />
              </div>
            )}
          </div>




        </div>

        {/* Game Controls */}
        <div className="flex flex-col gap-4 w-full md:w-auto">
          {/* Start Game Button */}
          <button
            onClick={startGame}
            className={`bg-blue-600 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition-all duration-200 ${
              !selectedForm ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={!selectedForm}
          >
            Start New Game
          </button>

          {/* Timer Input and Display */}
          <div className="flex items-center gap-4">
            <label htmlFor="timerInput" className="text-lg font-semibold text-gray-700">
              Timer (seconds):
            </label>
            <input
              id="timerInput"
              type="number"
              min="1"
              value={timerSeconds}
              onChange={(e) => setTimerSeconds(Number(e.target.value))}
              className="border border-gray-300 rounded w-20 px-3 py-2 focus:outline-none focus:ring focus:ring-sky-300"
            />
          </div>
        </div>
      </div>
      </div>
      {/* Action Buttons */}
          {/* Toggle Mode Selector Button */}
          {!showModeSelector && columns.length > 0 && (
            <button
              onClick={toggleModeSelector}
              className="m-4 bg-blue-700 text-gray-200 py-1 px-3 rounded hover:bg-blue-800 transition-all duration-200"
            >
              Show Mode Selection
            </button>
          )}      
      {showModeSelector && columns.length > 0 && (
            <button
              onClick={toggleModeSelector}
              className="m-4 bg-blue-700 text-gray-200 py-1 px-3 rounded hover:bg-blue-800 transition-all duration-200"
            >
              Hide Mode Selection
            </button>
          )}
      <div className="mb-8 flex flex-col md:flex-row items-center gap-4">
        <DrawButton onDraw={handleDrawWord} disabled={!gameStarted} />
        <button
          onClick={handleSaveAsPDF}
          disabled={!gameStarted || drawnWords.length === 0}
          className={`bg-purple-700 text-white py-2 px-4 rounded-lg shadow hover:bg-purple-800 transition-all duration-200 ${
            !gameStarted || drawnWords.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          Save Results as PDF
        </button>
      </div>

      {/* Timer */}
      <div className="mb-8">
        <Timer initialTime={timerSeconds} onReset={() => setTimerSeconds(15)} />
      </div>

      {/* Word Lists */}
      <div className="flex flex-col md:flex-row w-full max-w-4xl gap-8">
        {/* Available words */}
        <div className="flex-1 h-80 overflow-y-auto bg-white shadow-md rounded-lg p-4">
          <h3 className="text-2xl font-bold text-sky-800 mb-4 text-center">Available Words</h3>
          <ul>
            {availableWords.length > 0 ? (
              availableWords.map((word, index) => (
                <li key={index} className="p-2 text-lg border-b border-gray-200">
                  {availableWords.length - index}. {word}
                </li>
              ))
            ) : (
              <p className="text-center text-gray-500">No available words.</p>
            )}
          </ul>
        </div>

        {/* Drawn words with PlayButton */}
        <div className="flex-1 h-80 overflow-y-auto bg-white shadow-md rounded-lg p-4">
          <h3 className="text-2xl font-bold text-sky-800 mb-4 text-center">Drawn Words</h3>
          <ul>
            {drawnWords.length > 0 ? (
              drawnWords.map(({ word, drawOrder }) => (
                <li key={`${word}-${drawOrder}`} className="p-2 text-lg border-b border-gray-200 flex justify-between items-center">
                  <span>
                    {drawOrder}. {word}
                  </span>
                  <PlayButton word={word} />
                </li>
              ))
            ) : (
              <p className="text-center text-gray-500">No words drawn yet.</p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BingoApp;
