import React, { useState } from 'react';
import PlayButton from './widgets/PlayButton';

const Flashcard = ({ flashcard, isActive }) => {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    if (isActive) {
      setFlipped((prev) => !prev);
    }
  };

  return (
    <div
      className={`relative ${isActive ? 'cursor-pointer' : 'cursor-default'} perspective`}
      onClick={handleFlip}
      tabIndex={isActive ? 0 : -1}
      role="button"
      aria-pressed={flipped}
      onKeyDown={(e) => {
        if (isActive && (e.key === 'Enter' || e.key === ' ')) {
          handleFlip();
        }
      }}
    >
      <div
        className={`relative w-full h-0 pb-[65%] sm:pb-[60%] md:pb-[55%] lg:pb-[50%] xl:pb-[45%] transform transition-transform duration-700 ${
          flipped ? 'rotate-y-180' : ''
        }`}
        style={{
          transformStyle: 'preserve-3d',
        }}
      >
        {/* Front Side */}
        
        <div
          className="absolute top-0 left-0 w-full h-full bg-white border border-gray-300 rounded-lg shadow-lg flex flex-col items-center justify-center p-4 sm:p-6 backface-hidden"
          style={{
            backfaceVisibility: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <div className='flex flex-col justify-center items-center'>
            <PlayButton word={flashcard.front} lang="en-UK" size={28}/>
          <p className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800">
            {flashcard.front}
            
          </p>

          </div>

          {/* Display category on the front */}
          <p className="mt-2 text-sm sm:text-lg font-medium text-gray-500">
            ({flashcard.category})
          </p>
        </div>

        {/* Back Side */}
        <div
          className="absolute top-0 left-0 w-full h-full bg-purple-800 border border-gray-300 rounded-lg shadow-lg flex items-center justify-center p-4 sm:p-6 text-white transform rotate-y-180 backface-hidden"
          style={{
            backfaceVisibility: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: 'rotateY(180deg)',
          }}
        >
          <p className="text-sm sm:text-lg md:text-lg">{flashcard.back}</p>
        </div>
      </div>
      
    </div>
  );
};

export default Flashcard;
