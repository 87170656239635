// src/widgets/UserInfoCard.jsx

import React from 'react';
import { FaEnvelope, FaUserShield, FaAdn } from 'react-icons/fa';

const UserInfoCard = ({ user }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 border-l-4 border-green-600 flex-1">
      <div className="flex items-center h-full">
        {/* Icon Section */}
        <div className="bg-blue-400 bg-opacity-20 p-4 rounded-full mr-4 flex-shrink-0">
          <FaUserShield className="text-blue-700 text-4xl" />
        </div>

        {/* User Details */}
        <div className="flex flex-col">
          <div className="flex items-center mb-2">
            <FaEnvelope className="mr-2 text-lg text-sky-900" />
            <p className="text-lg">{user.email}</p>
          </div>
          <div className="flex items-center">
            <FaAdn className="mr-2 text-lg text-sky-900" />
            <p className="text-lg capitalize">{user.role}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoCard;
