// QrScanner.js
import React, { useRef, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import jsQR from 'jsqr';

function QrScanner({ onScanSuccess, facingMode='environment' }) {
  const webcamRef = useRef(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    // Clear any existing intervals when component unmounts or facingMode changes
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  useEffect(() => {
    const capture = () => {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, img.width, img.height);
          const imageData = ctx.getImageData(0, 0, img.width, img.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            onScanSuccess(code.data);
          }
        };
      }
    };

    // Capture every 500ms
    const id = setInterval(capture, 500);
    setIntervalId(id);

    return () => {
      clearInterval(id);
    };
  }, [onScanSuccess]);

  const videoConstraints = {
    facingMode: facingMode,
  };

  return (
    <Webcam
      audio={false}
      ref={webcamRef}
      screenshotFormat="image/png"
      videoConstraints={videoConstraints}
      style={{ width: '100%' }}
    />
  );
}

export default QrScanner;
