// src/components/Dashboard.jsx

import React from 'react';
import UserInfoCard from '../widgets/UserInfoCard';
import StudentDashboard from './StudentDashboard';
import AdminDashboard from './AdminDashboard';

const Dashboard = ({ user }) => {
  return (
    <div className="container mx-auto p-6">
      {/* Welcome and User Info Cards */}
      <div className="flex justify-between p-2 items-stretch gap-6 mb-12">
        {/* Welcome Message */}
        <div className="bg-gray-100 shadow-lg rounded-lg p-6 border-l-4 border-green-600 flex-1">
          <h2 className="text-2xl font-semibold mb-2 text-center text-bluey-900">
            Welcome,<br/> {user.username}!
          </h2>
        </div>

        {/* User Information Card */}
        <UserInfoCard user={user} />
      </div>

      {/* Conditional Rendering */}
      {user.role === 'student' && <StudentDashboard user={user} />}
      {user.role === 'admin' && <AdminDashboard />}
    </div>
  );
};

export default Dashboard;
