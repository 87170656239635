// src/components/SceneTaskApp.js

import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import { FaFileUpload } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import './SceneTaskApp.css';

const SceneTaskApp = () => {
  const [scenarios, setScenarios] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showCard, setShowCard] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const audioRef = useRef(null);
  const soundTimeoutRef = useRef(null);

  // Function to shuffle an array
  const shuffleArray = (array) => {
    const newArray = array.slice(); // Create a copy
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  // Function to handle CSV file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const data = results.data.filter((row) => row.category && row.task);
        const shuffledData = shuffleArray(data);
        setScenarios(shuffledData);
        pickNextItem(shuffledData);
        setFileUploaded(true);

        // Initialize audio object
        audioRef.current = new Audio('/witch_laugh.mp3');

        // Function to play sound and schedule next play
        const playSound = () => {
          audioRef.current.play().catch((error) => {
            console.error('Error playing audio:', error);
          });

          // Schedule next play
          const randomInterval = Math.floor(Math.random() * (30 - 15 + 1) + 15) * 1000; // 15-30 seconds
          soundTimeoutRef.current = setTimeout(playSound, randomInterval);
        };

        // Start the sound playback after initial random delay
        const initialDelay = Math.floor(Math.random() * (30 - 15 + 1) + 15) * 1000;
        soundTimeoutRef.current = setTimeout(playSound, initialDelay);
      },
      error: (error) => {
        console.error('Error parsing CSV file:', error);
      },
    });
  };

  // Function to pick the next item
  const pickNextItem = (items) => {
    if (items.length === 0) {
      setCurrentItem(null);
      return;
    }
    setCurrentItem(items[0]);
  };

  // Handle Next Task with Custom Popup
  const handleNext = () => {
    setShowPopup(true); // Show the custom popup
  };

  // Confirm to proceed to the next task
  const confirmNext = () => {
    setShowPopup(false); // Hide the popup
    setShowCard(false); // Start exit animation
    setTimeout(() => {
      const updatedItems = scenarios.filter((item) => item !== currentItem);
      const shuffledItems = shuffleArray(updatedItems);
      setScenarios(shuffledItems);
      pickNextItem(shuffledItems);
      setShowCard(true); // Start enter animation
    }, 500);
  };

  // Cancel action for the popup
  const cancelNext = () => {
    setShowPopup(false); // Simply hide the popup
  };

  // Cleanup function when component unmounts
  React.useEffect(() => {
    return () => {
      if (soundTimeoutRef.current) {
        clearTimeout(soundTimeoutRef.current);
      }
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-orange-600 via-purple-700 to-black text-white p-4">
      {/* File upload input */}
      {!fileUploaded && (
        <div className="absolute top-[10vh] left-4 flex items-center gap-2">
          <FaFileUpload size={24} />
          <input
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
            className="cursor-pointer bg-black text-orange-400 px-2 py-1 text-sm rounded shadow-md hover:bg-gray-800 transition"
          />
        </div>
      )}

      {/* Displaying the task card with animation */}
      <CSSTransition in={showCard} timeout={500} classNames="fade">
        <div className="bg-white/10 backdrop-blur-lg border border-white/20 rounded-lg shadow-md p-6 max-w-lg text-center">
          {currentItem ? (
            <>
              <h1 className="text-3xl md:text-4xl font-bold mb-4 text-orange-400 drop-shadow-md">
                {currentItem.category}
              </h1>
              <p className="text-xl md:text-2xl mb-8 text-white/80">
                {currentItem.task}
              </p>
              <button
                onClick={handleNext}
                className="bg-orange-500 text-white px-6 py-2 rounded-full text-lg font-semibold shadow-lg hover:bg-orange-600 transition transform hover:-translate-y-1"
              >
                NEXT
              </button>
            </>
          ) : (
            <h1 className="text-3xl md:text-5xl font-bold text-center text-gray-200">
              🎉 Wszystkie zadania zostały wykonane! -{' '}
              <span className="text-xl">albo nie rozpoczęto gry ;)</span> 🎉
            </h1>
          )}
        </div>
      </CSSTransition>

      {/* Custom Confirmation Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-white/10 backdrop-blur-md border border-white/20 rounded-lg p-6 max-w-sm text-center shadow-lg">
            <h2 className="text-2xl font-bold mb-4 text-orange-300">
              Are you sure?
            </h2>
            <p className="text-white/80 mb-6">
              Just confirm it was not a misclick
            </p>
            <div className="flex justify-around">
              <button
                onClick={confirmNext}
                className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600 transition"
              >
                I'm sure!
              </button>
              <button
                onClick={cancelNext}
                className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600 transition"
              >
                Abort!
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SceneTaskApp;
